#SearchTxt {
    margin: 0 0;
    border: solid 0px #8a8886;
}

		
.Flipped, .Flipped .Content{
    transform: rotateX(180deg);
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* #wrapper1, #wrapper2{border: none 0px RED;  overflow-x: scroll; overflow-y:hidden;}
#div1 {width:3000px; } */