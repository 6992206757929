.contextMenuLabel {
  font-size: 14px;
  background-color: transparent;
  border-width: 0;
  position:absolute;
  right:0px;
}

.contextMenuLabel:hover {
  background-color: transparent;
  color: #006ed2;
}

.changeViewHeader {
  position: absolute;
  right: '30px';
  padding: '25px 0 0 0';
}