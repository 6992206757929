 .rootBackground {
        height: 100%;

 }

.root {
    flexGrow: 1;
}

.cardHeaderSection {
    padding: 16px 0 0 16px !important;
}

.cardHeader {
    font-family: 'PharmaCond' !important;
    font-size: 24px !important;
    color: #323130 ;
    font-weight: 600 !important;
}

 .card {
    width: 200;
    height: 320;
    color: #000;
    margin: 8px 16px !important;
 }

 .listCard {
      width: 300px !important;
      height: 320;
      color: #000;
      margin: 8px 16px !important;
 }

.cardContent
{
   padding: 16px 0;
}

.listTable {
  background-color: #f3f2f1 !important;
}

.sepLine
{
  margin: 16px 0;
  color: #bdbdbd;
}

.subtitle{
    padding: 16px 0;
    font-weight: 600;
    font-family: 'SegoeUI';
}

.viewLink {
      color: #006ed2;
 }

.masterButton
{
    padding: 5px 19px 7px;
    border-radius: 2px;
    border: solid 1px #0078d4;
    background-color: #0078d4;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
 }

 .masterButtonDisabled{
     padding: 5px 19px 7px;
     border-radius: 2px;
     border: solid 1px #8a8886;
     background-color: #c8c8c8;
     color: #605e5c;
     cursor: not-allowed;
  }

  .descriptionField{
    color: #000000;
    listStylePosition: inside;
    height: 52px;
    paddingBottom: 10px;
    textOverflow: ellipsis;
    overflow: hidden;
    }

 .mappingTableCardHeader{
    background-color: #f1f2f3;
    color: #323130;
    font-family: 'SegoeUI';
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
 }

.accordionHeader{
  background-color: #f1f2f3 !important;
  flex-direction: row-reverse;
  padding: 0  !important;

}

.accordionSection{
   padding: 0 16px !important;
}

 .viewItemLink{
     border: 0;
     background-color: transparent;
     color: #006ed2;
     font-size: 14px;
     cursor: grab;
     text-align: left;
 }

.viewItemLinkIcon {
     border: 1;
     background-color: null;
     color: darkgrey;
     font-size: 14px !important;
     cursor: grab;
     float: right;
}

.icon {
     font-size: 20px !important;
     padding-right: 1rem;
     padding-left: 1rem;
}

.boxOutline{
margin-left: 5rem;
margin-right: 5rem;
}

.heading {
   font-weight: bold !important;

}

.lastHeading{
    font-weight: bold !important;
    padding-left: 24px;
}

.listContext{
  border-bottom: solid 1px #dcdcdc !important;
}

.sectionheader {
     font-family: SegoeUI;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1;
     letter-spacing: normal;
     color: #323130;
     padding: 0px 16px;
 }

.accordCardBackground {
  height: 100%;
  background-color: transparent;
  width: 100%;
  border:none !important;

}

.tmItemText {
    padding: 0 8px 0 20px;
    font-weight: 600 !important;
    font-size: 14px !important;
    font-family: SegoeUI !important;
  }

 .listHeader {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: SegoeUI !important;
 }

 .tbllistHeader {
  font-size:   20px !important;
  font-weight: 600 !important;
  font-family: SegoeUI !important;
  color: #323130;
  margin: 49px 9px 24px 78px;
 }

.filterButtonStyle {
 padding: 7px 19px;
 margin: 0 16px;
 background-color: #fff;
 color: #323130;
 border-radius: 2px;
 border: solid 1px #8a8886;
 cursor: pointer;
}

.filterButtonLabel {
 text-transform: capitalize;
 background-color: #EAEAEA;
 text-Overflow: ellipsis;
 overflow: hidden;
 whiteSpace: nowrap;
 width: auto;
 marginRight: 1rem;
}

.closeButton {
 background-color: #fff;
 border: 0;
 color: #605e5c;
 font-size: 18px;
 padding: 0 16px;
 margin-right: 16px;
 cursor: pointer
}

.filterDialog {
     position: absolute;
     left: 10;
     top: 50;
 }

 .tmHeader {
     font-size: 24px;
     font-weight: 700;
     font-family: PharmaCond;
     color: #323130;
 }

 .tmItem {
      color: #006ed2 !important;
      font-size: 14px;
      font-weight: 600 !important;
      background-color: #ffffff !important;
      margin: 16px 0 !important;
 }

.editControlSection {
     background-color: #f6f6f6;
     padding: 24px;
}

.descAlign {
     width: 60%;
     overflow-wrap: break-word;
}

.tableSummaryHeader {
    margin: 0 0 8px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #555;
    padding: 14px 0 0 0;
}

.mappingCategory {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #323130;
}

.notificationAlign {
 width: 38%;
 float: right;
 height: 120px;
}

.descBottom{
     padding-bottom: 10px
 }

 .titleSection {
     background-color: #0078d4;
     height: 50px;
     color: #fff;
     padding: 16px 33px;
  }

.titleHeaderRow {
 font-size: 20px;
 font-weight: 600;
}

.titleRow {
 padding: 8px 0;
}

.boxed {
 border: 2px solid lightgrey;
 height: 300px;
}

 .descHeader {
    font-weight: bold;
    color: #32313;
    font-size: 14px;
    padding: 14px 0 16px 10px;
  }

 .editControlSectionHeader {
    color: black;
    font-weight: 200;
    padding: 0 1.5em 1em 1em;
    width: 100%;
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    margin: 1px;
    height: 140px;
  }

  .editHeader {
    padding: 20px 0px 0px 10px;
    color: black;
    fontWeight: 600;
    font-size: 18px;
  }

 .upperSpace {
      padding-op: 10px;
      height: 100%;
    }

 .notificationType {
      margin-top: 12px;
      padding-left: 15px;
      font-weight: bold;
  }


.Mui-selected {
     background-color: rgba(0, 0, 0, 0.08) !important;
}

.mappingDescDivider {
  border-left: 1px #cdcdcd solid;
  padding: 0px 15px;
  margin: 16px 15px 0 28px;
}

.mappingListSubHeader{
    font-size: 16px;
    font-weight: 600;
    color: #323130;
    margin: 0px 0px 16px 0px;
}

.mappingListTableName{
  margin: 0 0 8px;
  font-family: SegoeUI !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.43;
  letter-spacing: normal;
  color: #323130 !important;
}

.whiteBtn {
     background-color: transparent;
     border: 0px;
     padding: 10px 0 10px 0;
     color: #323130;
     padding-right: 1rem;
}

.addValueLinkIcon {
     border: 0;
     background-color: null;
     color: black;
     font-size: 8px;
     cursor: grab;
     float: left;
     margin-right: 1rem;
 }

 .addNewMappingSection {
    margin: 31.5px 0 0 0px;
    padding: 16px 0px 16px 16px;
    border-radius: 2px;
    background-color: #f6f6f6;

  }

 .actionButtons {
    padding: 20px 0 20px 0
}

.cancelButton{
   margin: 0 16px 0 0;
}

.addValueButton{
  margin: 0 15px;
}

.addValuesSection{
  display: flex;
}

.addValueControlDiv{
    padding: 0 8px;
}

.tableListCategoryHeader{
  font-family: SegoeUI !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: normal;
  color: #323130;

}

.tableListCategoryLastUpdated{
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: normal;
  color: #323130;

}

.tableListHeaderSection{
    margin: 10px 24px 0 0 !important;

}

.tableListHeaderSectionItem{
    padding: 0 !important;
}

.builderDescriptionSection{
  display: flex;
  font-family: 'SegoeUI';
}

.completeIcon {
  border: 0;
  color: #00aa23;
  font-size: 12px;
  cursor: grab;
  padding: 16px 0 0 0;
  vertical-align: bottom;
}

.inCompleteIcon {
  border: 0;
  color: #555;
  font-size: 12px;
  cursor: grab;
  padding: 16px 0 0 0;
  vertical-align: bottom;
}

.uniqueSpan {
 padding: 0 6px;
 font-family: SegoeUI;
 font-size: 12px;
 line-height: 1.33;
 color: #323130;
}

.mappingDataInput {
    height: 20px;
    width: 60%;
    padding: 5px 8px 7px;
    borderRadius: 2px;
    border: solid 1px #a19f9d;
    backgroundColor: #fff;
  }