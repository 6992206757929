.searchBarContainer {
  width: 307px;
  border-radius: 2px;
  margin-left: 100px;
  display: flex;
}

.searchBtn {
  background: white;
  border: solid 1px #8a8886 !important;
  border-radius: 0 2px 2px 0;
  margin-left: -1px;
  width: 88px;
}

.searchBtn:hover {
  background: none;
}

.searchBtn span {
  font-weight: normal;
}

.searchBtn i {
  order: 5;
}

#searchBarTextField {
  width: 220px;
  height: 30px;
  border: 0px !important;
  padding: 0px 8px !important;
  color: black;
}
