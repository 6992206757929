@import url(https://fonts.googleapis.com/css?family=Roboto);
 .rootBackground {
        height: 100%;

 }

.root {
    flexGrow: 1;
}

.cardHeaderSection {
    padding: 16px 0 0 16px !important;
}

.cardHeader {
    font-family: 'PharmaCond' !important;
    font-size: 24px !important;
    color: #323130 ;
    font-weight: 600 !important;
}

 .card {
    width: 200;
    height: 320;
    color: #000;
    margin: 8px 16px !important;
 }

 .listCard {
      width: 300px !important;
      height: 320;
      color: #000;
      margin: 8px 16px !important;
 }

.cardContent
{
   padding: 16px 0;
}

.listTable {
  background-color: #f3f2f1 !important;
}

.sepLine
{
  margin: 16px 0;
  color: #bdbdbd;
}

.subtitle{
    padding: 16px 0;
    font-weight: 600;
    font-family: 'SegoeUI';
}

.viewLink {
      color: #006ed2;
 }

.masterButton
{
    padding: 5px 19px 7px;
    border-radius: 2px;
    border: solid 1px #0078d4;
    background-color: #0078d4;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
 }

 .masterButtonDisabled{
     padding: 5px 19px 7px;
     border-radius: 2px;
     border: solid 1px #8a8886;
     background-color: #c8c8c8;
     color: #605e5c;
     cursor: not-allowed;
  }

  .descriptionField{
    color: #000000;
    listStylePosition: inside;
    height: 52px;
    paddingBottom: 10px;
    textOverflow: ellipsis;
    overflow: hidden;
    }

 .mappingTableCardHeader{
    background-color: #f1f2f3;
    color: #323130;
    font-family: 'SegoeUI';
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
 }

.accordionHeader{
  background-color: #f1f2f3 !important;
  flex-direction: row-reverse;
  padding: 0  !important;

}

.accordionSection{
   padding: 0 16px !important;
}

 .viewItemLink{
     border: 0;
     background-color: transparent;
     color: #006ed2;
     font-size: 14px;
     cursor: grab;
     text-align: left;
 }

.viewItemLinkIcon {
     border: 1;
     background-color: null;
     color: darkgrey;
     font-size: 14px !important;
     cursor: grab;
     float: right;
}

.icon {
     font-size: 20px !important;
     padding-right: 1rem;
     padding-left: 1rem;
}

.boxOutline{
margin-left: 5rem;
margin-right: 5rem;
}

.heading {
   font-weight: bold !important;

}

.lastHeading{
    font-weight: bold !important;
    padding-left: 24px;
}

.listContext{
  border-bottom: solid 1px #dcdcdc !important;
}

.sectionheader {
     font-family: SegoeUI;
     font-size: 14px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1;
     letter-spacing: normal;
     color: #323130;
     padding: 0px 16px;
 }

.accordCardBackground {
  height: 100%;
  background-color: transparent;
  width: 100%;
  border:none !important;

}

.tmItemText {
    padding: 0 8px 0 20px;
    font-weight: 600 !important;
    font-size: 14px !important;
    font-family: SegoeUI !important;
  }

 .listHeader {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: SegoeUI !important;
 }

 .tbllistHeader {
  font-size:   20px !important;
  font-weight: 600 !important;
  font-family: SegoeUI !important;
  color: #323130;
  margin: 49px 9px 24px 78px;
 }

.filterButtonStyle {
 padding: 7px 19px;
 margin: 0 16px;
 background-color: #fff;
 color: #323130;
 border-radius: 2px;
 border: solid 1px #8a8886;
 cursor: pointer;
}

.filterButtonLabel {
 text-transform: capitalize;
 background-color: #EAEAEA;
 text-Overflow: ellipsis;
 overflow: hidden;
 whiteSpace: nowrap;
 width: auto;
 marginRight: 1rem;
}

.closeButton {
 background-color: #fff;
 border: 0;
 color: #605e5c;
 font-size: 18px;
 padding: 0 16px;
 margin-right: 16px;
 cursor: pointer
}

.filterDialog {
     position: absolute;
     left: 10;
     top: 50;
 }

 .tmHeader {
     font-size: 24px;
     font-weight: 700;
     font-family: PharmaCond;
     color: #323130;
 }

 .tmItem {
      color: #006ed2 !important;
      font-size: 14px;
      font-weight: 600 !important;
      background-color: #ffffff !important;
      margin: 16px 0 !important;
 }

.editControlSection {
     background-color: #f6f6f6;
     padding: 24px;
}

.descAlign {
     width: 60%;
     overflow-wrap: break-word;
}

.tableSummaryHeader {
    margin: 0 0 8px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #555;
    padding: 14px 0 0 0;
}

.mappingCategory {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #323130;
}

.notificationAlign {
 width: 38%;
 float: right;
 height: 120px;
}

.descBottom{
     padding-bottom: 10px
 }

 .titleSection {
     background-color: #0078d4;
     height: 50px;
     color: #fff;
     padding: 16px 33px;
  }

.titleHeaderRow {
 font-size: 20px;
 font-weight: 600;
}

.titleRow {
 padding: 8px 0;
}

.boxed {
 border: 2px solid lightgrey;
 height: 300px;
}

 .descHeader {
    font-weight: bold;
    color: #32313;
    font-size: 14px;
    padding: 14px 0 16px 10px;
  }

 .editControlSectionHeader {
    color: black;
    font-weight: 200;
    padding: 0 1.5em 1em 1em;
    width: 100%;
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    margin: 1px;
    height: 140px;
  }

  .editHeader {
    padding: 20px 0px 0px 10px;
    color: black;
    fontWeight: 600;
    font-size: 18px;
  }

 .upperSpace {
      padding-op: 10px;
      height: 100%;
    }

 .notificationType {
      margin-top: 12px;
      padding-left: 15px;
      font-weight: bold;
  }


.Mui-selected {
     background-color: rgba(0, 0, 0, 0.08) !important;
}

.mappingDescDivider {
  border-left: 1px #cdcdcd solid;
  padding: 0px 15px;
  margin: 16px 15px 0 28px;
}

.mappingListSubHeader{
    font-size: 16px;
    font-weight: 600;
    color: #323130;
    margin: 0px 0px 16px 0px;
}

.mappingListTableName{
  margin: 0 0 8px;
  font-family: SegoeUI !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.43;
  letter-spacing: normal;
  color: #323130 !important;
}

.whiteBtn {
     background-color: transparent;
     border: 0px;
     padding: 10px 0 10px 0;
     color: #323130;
     padding-right: 1rem;
}

.addValueLinkIcon {
     border: 0;
     background-color: null;
     color: black;
     font-size: 8px;
     cursor: grab;
     float: left;
     margin-right: 1rem;
 }

 .addNewMappingSection {
    margin: 31.5px 0 0 0px;
    padding: 16px 0px 16px 16px;
    border-radius: 2px;
    background-color: #f6f6f6;

  }

 .actionButtons {
    padding: 20px 0 20px 0
}

.cancelButton{
   margin: 0 16px 0 0;
}

.addValueButton{
  margin: 0 15px;
}

.addValuesSection{
  display: flex;
}

.addValueControlDiv{
    padding: 0 8px;
}

.tableListCategoryHeader{
  font-family: SegoeUI !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: normal;
  color: #323130;

}

.tableListCategoryLastUpdated{
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: normal;
  color: #323130;

}

.tableListHeaderSection{
    margin: 10px 24px 0 0 !important;

}

.tableListHeaderSectionItem{
    padding: 0 !important;
}

.builderDescriptionSection{
  display: flex;
  font-family: 'SegoeUI';
}

.completeIcon {
  border: 0;
  color: #00aa23;
  font-size: 12px;
  cursor: grab;
  padding: 16px 0 0 0;
  vertical-align: bottom;
}

.inCompleteIcon {
  border: 0;
  color: #555;
  font-size: 12px;
  cursor: grab;
  padding: 16px 0 0 0;
  vertical-align: bottom;
}

.uniqueSpan {
 padding: 0 6px;
 font-family: SegoeUI;
 font-size: 12px;
 line-height: 1.33;
 color: #323130;
}

.mappingDataInput {
    height: 20px;
    width: 60%;
    padding: 5px 8px 7px;
    borderRadius: 2px;
    border: solid 1px #a19f9d;
    backgroundColor: #fff;
  }
.searchlist{    
    width:20rem !important
}

.listHeader{
    font-size: '20px';
    font-family: 'PharmaCond';
   }

.editButton{
    background-color: '#0078d4';
    border: 'none';
    color: '#fff';
    padding: "5px 12px 7px 16px";
    text-align: 'center';
    font-size: '14px';
    font-weight: '600';
    cursor: 'pointer';
    border-radius:'2';
}   

.buttonSubmit{
    padding: '7px 19px';
    margin: '0 16px';
    background-color:'#c8c8c8';
    color: '#605e5c';
    font-weight:'600';    
    border: 'none';
    cursor: 'pointer';
   }

.MuiCheckbox-colorSecondary.Mui-checked {
  color:none;
}
.MuiIconButton-colorSecondary:hover {
    background-color:none;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .MuiAccordionSummary-root {    
    background-color: rgba(0,0,0,0.08);
}

.MuiSvgIcon-root {
    font-size: 0.75rem !important;  
}
.MuiAccordionDetails-root {
    display: initial !important;
    padding: 0px 0px 0px !important; 
    display: grid !important;     
}
/* .MuiAccordionSummary-content { 
    text-decoration: underline;
} */

.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.54);
    height: 10px !important;
}

.MuiButtonGroup-root {
    display: inline-grid!important;
    border-radius: 4px;
}

.MuiButton-sizeSmall {
    text-transform: capitalize !important;
    font-size: 10px !important;
}

/* .MuiTypography-displayBlock
{
    display: block;
    font-weight: bold !important;
} */
@font-face {
	font-family: 'PharmaCond';
	src: local('PharmaCond'),
	url(/static/media/pharma-regularcond.eff51ada.eot),
    	url(/static/media/pharma-regularcond.749c36ba.woff) format('woff'),
    	 url(/static/media/pharma-regularcond.3ed973d6.ttf) format('truetype');
    font-weight: normal ;
    font-style: normal;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Light"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Semilight"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Bold"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Semibold"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
    font-weight: 700;
}

*{
    margin: 0;
    padding: 0;
    font-family:'SegoeUI';
     font-size: 14px;;
}
html, body {
    margin: 0;
    height: 100%;
}
#root{
    width: 100%;
    height: 100%;
    background-Color:#f3f2f1;
}
#main{
    width: 100%;
    height: 300px;
    margin: 0 auto;
    transform: translateY(150px);
    font-family: 'Roboto', sans-serif;
}



.activateButton{
    background-color: #0078d7 !important;
    color: #fff !important;
}

.activateButton:focus{
    background-color: #106ebe !important;
 }

.activateButton:hover {
  background-color: #106ebe !important;
}

.activateButton:active {
  background-color: #323130 !important;
}

.disableButtonBlock{
  background-color: grey;
  width: 138px;
  height: 32px;
  margin: 0 50px 0 0;
  padding-top:8px;
  text-Align: center;
  font-Size: 14px;
  font-Weight: 300;
}

.disableButton{
  background-color: grey;
  cursor: none !important;
  color: #fff;
}

.errorDialog {
    max-width: 603px;

}

.errorDialogFooter {
           margin-left: -28px;
           width: calc(100% + 28px);
           padding-top: 17px;
           padding-right: 24px;
           margin-top: 38px;
           border-top: 1px solid #edebe9;
    }

    .errorDialogHeader{
         padding: 16px 32px;

    }

    .errorDialogHeaderInner{
           display: flex;
           align-items: center;
    }

    .errorDialogHeaderLogo{
      width: 48px;
      margin-right: 20px;
    }

    .errorDialogHeaderTitle{
      font-size: 20px;
      font-weight: 600;
      margin: 0;

    }

    .errorDialogList{
          margin: 0;
           padding: 0;
           list-style: none;
    }

    .errorDialogListItem{
         position: relative;
            padding-left: 16px;
            margin-bottom: 16px;
    }

    .errorDialogDefaultBtn{
          background: none;
              border: 1px solid #8a8886;
    }
     
    .errorDialogDefaultBtnBlack{
      background: none;
          border: 1px solid black;
}    
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color:#605e5c;
}

.Rectangle {
    height: 50px;
    margin: 0px 10px 10px 1px;
    padding: 34px 10px 4px;
    background-color: #f3f2f1;
  }

  .OLi-Admin-Portal {
    width: auto;
    height: 40px;
    margin: 0 8px 3px 20px;
    font-family: 'PharmaCond';
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.8px;
    color: #878787;
  }

  .Admin-Library {
    width: 1440px;
    height: 1395px;
    background-color: #f3f2f1;
  }

  .Admin-Master {   
    width: auto;
    height: 40px;
    margin: 0 45px 3px 2px;
    font-family: 'PharmaCond';
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.33px;
    color: #323232;
  }

  .Admin-Menu {
    width: 100px;
    height: 40px;
    margin: 0 5px 3px 8px;        
    line-height: 1.6;
    letter-spacing: 0.33px;    
  }

  .MuiSvgIcon-root {   
    width: 2em !important;
    height: 2em !important;
    /* font-size: 1rem !important;   */
  }

  .Admin-Search {
    width: 200px;
    height: 22px;
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #323130;
  }

  .Admin-btn{    
    width: 90px;
    height: 32px;
    margin: 0px 20px 15px 17px;
    padding: 3px 16px 3px 11px;
    border-radius: 2px;
    background-color: #0078d4 !important;      
  }

  .Admin-btn-o{ 
    height: 32px;
    margin: 0px 20px 5px 17px;
    padding: 3px 16px 3px 11px;
    border-radius: 2px;
    background-color: #0078d4 !important;         
    width: 115px;
  }

  .OLi-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  /* .MuiButton-containedSizeSmall {
    padding: 2px 10px;
    font-size: 0.8125rem;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
} */

.MuiButton-root {
  padding:6px 6px;
}

.MuiButton-containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
    margin-top: 0.8rem !important;
    margin-left: 1rem !important;
}

.btn-search{
  margin-left: 0rem !important;
  background-color: white !important;
  color: #323130 !important; 
  margin: 12px 20px 5px 17px;
  padding: 3px 16px 3px 11px;
  border-radius: 0px;
  margin-top: 0.7rem !important;
  border: 1;    
  height: 2.6rem;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  color: #605e5c !important;

}
.input-s{
    padding: 8px 12px;
    height: 2.6rem;
    margin-top: 0.7rem !important;
}

.img-s1{
  color:transparent;
  background-color: #f3f2f1;
  width: 30px;
  height: 30px;
  padding-right: 2px;
  padding-top: 6px; 
}

.img-s{
  color:white;
  width: 30%;
  padding-right: 6px; 
}
.Admin-s{
  margin-left:10rem;
}

.search-icon{
  width:1rem;
  color:#323130;
  font-size: 0.8rem ! important;
}

.MuiAccordionDetails-root {
  display: initial !important;
  padding: 8px 16px 16px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiSelect-icon {
  top: calc(50% - 20px) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #0078d4 !important;
}
/* 
.MuiAccordionSummary-expandIcon {
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-right: -1px;
} */

.ms-Button.ms-Button--icon.ms-Dialog-button.ms-Dialog-button--close.root-59{
  color:black !important;
}

.AdminMasterSearchContainer{
  margin-top: 11px;
}
.searchBarContainer {
  width: 307px;
  border-radius: 2px;
  margin-left: 100px;
  display: flex;
}

.searchBtn {
  background: white;
  border: solid 1px #8a8886 !important;
  border-radius: 0 2px 2px 0;
  margin-left: -1px;
  width: 88px;
}

.searchBtn:hover {
  background: none;
}

.searchBtn span {
  font-weight: normal;
}

.searchBtn i {
  order: 5;
}

#searchBarTextField {
  width: 220px;
  height: 30px;
  border: 0px !important;
  padding: 0px 8px !important;
  color: black;
}

input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
button[type=button] {
    border: 0px solid #fff,
}
#SearchTxt {
    margin: 0 0;
    border: solid 0px #8a8886;
}

		
.Flipped, .Flipped .Content{
    transform: rotateX(180deg);
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* #wrapper1, #wrapper2{border: none 0px RED;  overflow-x: scroll; overflow-y:hidden;}
#div1 {width:3000px; } */
.searchlist{    
    width:20rem !important
}

.listHeader{
    font-size: '20px';
    font-family: 'PharmaCond';
   }

.editButton{
    background-color: '#0078d4';
    border: 'none';
    color: '#fff';
    padding: "5px 12px 7px 16px";
    text-align: 'center';
    font-size: '14px';
    font-weight: '600';
    cursor: 'pointer';
    border-radius:'2';
}   

.buttonSubmit{
    padding: '7px 19px';
    margin: '0 16px';
    background-color:'#c8c8c8';
    color: '#605e5c';
    font-weight:'600';    
    border: 'none';
    cursor: 'pointer';
   }

.MuiCheckbox-colorSecondary.Mui-checked {
  color:none;
}
.MuiIconButton-colorSecondary:hover {
    background-color:none;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .MuiAccordionSummary-root {    
    background-color: rgba(0,0,0,0.08);
}

.MuiSvgIcon-root {
    font-size: 0.75rem !important;  
}
.MuiAccordionDetails-root {
    display: initial !important;
    padding: 0px 0px 0px !important; 
    display: grid !important;     
}
/* .MuiAccordionSummary-content { 
    text-decoration: underline;
} */

.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.54);
    height: 10px !important;
}

.MuiButtonGroup-root {
    display: inline-grid!important;
    border-radius: 4px;
}

.MuiButton-sizeSmall {
    text-transform: capitalize !important;
    font-size: 10px !important;
}

/* .MuiTypography-displayBlock
{
    display: block;
    font-weight: bold !important;
} */
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
button[type=button] {
    border: 0px solid #fff,
}
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
input[type="text"],
select {
  margin: 0;
  border: 0px solid #fff;
}

input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 84px;
    background-color: #99c5ed
}

.MuiAccordionSummary-root {
    min-height: 68px;
    background-color: #cce2f6;
    
}
.Policy-Europe-Busin {
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #323130;
  }
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
.link-s{
  text-decoration: none;
  padding-left:8px;  
  padding-top:10px;
  padding-bottom:10px;
}   

.Admin-Breadcrumb{
  margin-bottom: 2rem;
}
.contextMenuLabel {
  font-size: 14px;
  background-color: transparent;
  border-width: 0;
  position:absolute;
  right:0px;
}

.contextMenuLabel:hover {
  background-color: transparent;
  color: #006ed2;
}

.changeViewHeader {
  position: absolute;
  right: '30px';
  padding: '25px 0 0 0';
}
#EventSubmitterId {
    margin: 0 0;
}
.SelectDecor {
    width: 300
}
#EventSubmitterId {
    margin: 0 0;
}
.SelectDecor {
    width: 300
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 84px;
    background-color: #99c5ed
}

.MuiAccordionSummary-root {
    min-height: 68px;
    background-color: #cce2f6;
    
}
.Policy-Europe-Busin {
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #323130;
  }
input[type=text], select {
    margin: 0;
    border: 0px solid #fff,
}
button[type=button] {
    border: 0px solid #fff,
}
