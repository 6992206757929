@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
	font-family: 'PharmaCond';
	src: local('PharmaCond'),
	url('../fonts/pharma-regularcond.eot'),
    	url('../fonts/pharma-regularcond.woff') format('woff'),
    	 url('../fonts/pharma-regularcond.ttf') format('truetype');
    font-weight: normal ;
    font-style: normal;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Light"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Semilight"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Bold"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: SegoeUI;
    src:
        local("Segoe UI Semibold"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
    font-weight: 700;
}

*{
    margin: 0;
    padding: 0;
    font-family:'SegoeUI';
     font-size: 14px;;
}
html, body {
    margin: 0;
    height: 100%;
}
#root{
    width: 100%;
    height: 100%;
    background-Color:#f3f2f1;
}
#main{
    width: 100%;
    height: 300px;
    margin: 0 auto;
    transform: translateY(150px);
    font-family: 'Roboto', sans-serif;
}



.activateButton{
    background-color: #0078d7 !important;
    color: #fff !important;
}

.activateButton:focus{
    background-color: #106ebe !important;
 }

.activateButton:hover {
  background-color: #106ebe !important;
}

.activateButton:active {
  background-color: #323130 !important;
}

.disableButtonBlock{
  background-color: grey;
  width: 138px;
  height: 32px;
  margin: 0 50px 0 0;
  padding-top:8px;
  text-Align: center;
  font-Size: 14px;
  font-Weight: 300;
}

.disableButton{
  background-color: grey;
  cursor: none !important;
  color: #fff;
}

.errorDialog {
    max-width: 603px;

}

.errorDialogFooter {
           margin-left: -28px;
           width: calc(100% + 28px);
           padding-top: 17px;
           padding-right: 24px;
           margin-top: 38px;
           border-top: 1px solid #edebe9;
    }

    .errorDialogHeader{
         padding: 16px 32px;

    }

    .errorDialogHeaderInner{
           display: flex;
           align-items: center;
    }

    .errorDialogHeaderLogo{
      width: 48px;
      margin-right: 20px;
    }

    .errorDialogHeaderTitle{
      font-size: 20px;
      font-weight: 600;
      margin: 0;

    }

    .errorDialogList{
          margin: 0;
           padding: 0;
           list-style: none;
    }

    .errorDialogListItem{
         position: relative;
            padding-left: 16px;
            margin-bottom: 16px;
    }

    .errorDialogDefaultBtn{
          background: none;
              border: 1px solid #8a8886;
    }
     
    .errorDialogDefaultBtnBlack{
      background: none;
          border: 1px solid black;
}    
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color:#605e5c;
}

.Rectangle {
    height: 50px;
    margin: 0px 10px 10px 1px;
    padding: 34px 10px 4px;
    background-color: #f3f2f1;
  }

  .OLi-Admin-Portal {
    width: auto;
    height: 40px;
    margin: 0 8px 3px 20px;
    font-family: 'PharmaCond';
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.8px;
    color: #878787;
  }

  .Admin-Library {
    width: 1440px;
    height: 1395px;
    background-color: #f3f2f1;
  }

  .Admin-Master {   
    width: auto;
    height: 40px;
    margin: 0 45px 3px 2px;
    font-family: 'PharmaCond';
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.33px;
    color: #323232;
  }

  .Admin-Menu {
    width: 100px;
    height: 40px;
    margin: 0 5px 3px 8px;        
    line-height: 1.6;
    letter-spacing: 0.33px;    
  }

  .MuiSvgIcon-root {   
    width: 2em !important;
    height: 2em !important;
    /* font-size: 1rem !important;   */
  }

  .Admin-Search {
    width: 200px;
    height: 22px;
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #323130;
  }

  .Admin-btn{    
    width: 90px;
    height: 32px;
    margin: 0px 20px 15px 17px;
    padding: 3px 16px 3px 11px;
    border-radius: 2px;
    background-color: #0078d4 !important;      
  }

  .Admin-btn-o{ 
    height: 32px;
    margin: 0px 20px 5px 17px;
    padding: 3px 16px 3px 11px;
    border-radius: 2px;
    background-color: #0078d4 !important;         
    width: 115px;
  }

  .OLi-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  /* .MuiButton-containedSizeSmall {
    padding: 2px 10px;
    font-size: 0.8125rem;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
} */

.MuiButton-root {
  padding:6px 6px;
}

.MuiButton-containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
    margin-top: 0.8rem !important;
    margin-left: 1rem !important;
}

.btn-search{
  margin-left: 0rem !important;
  background-color: white !important;
  color: #323130 !important; 
  margin: 12px 20px 5px 17px;
  padding: 3px 16px 3px 11px;
  border-radius: 0px;
  margin-top: 0.7rem !important;
  border: 1;    
  height: 2.6rem;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  color: #605e5c !important;

}
.input-s{
    padding: 8px 12px;
    height: 2.6rem;
    margin-top: 0.7rem !important;
}

.img-s1{
  color:transparent;
  background-color: #f3f2f1;
  width: 30px;
  height: 30px;
  padding-right: 2px;
  padding-top: 6px; 
}

.img-s{
  color:white;
  width: 30%;
  padding-right: 6px; 
}
.Admin-s{
  margin-left:10rem;
}

.search-icon{
  width:1rem;
  color:#323130;
  font-size: 0.8rem ! important;
}

.MuiAccordionDetails-root {
  display: initial !important;
  padding: 8px 16px 16px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiSelect-icon {
  top: calc(50% - 20px) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #0078d4 !important;
}
/* 
.MuiAccordionSummary-expandIcon {
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-right: -1px;
} */

.ms-Button.ms-Button--icon.ms-Dialog-button.ms-Dialog-button--close.root-59{
  color:black !important;
}

.AdminMasterSearchContainer{
  margin-top: 11px;
}