.searchlist{    
    width:20rem !important
}

.listHeader{
    font-size: '20px';
    font-family: 'PharmaCond';
   }

.editButton{
    background-color: '#0078d4';
    border: 'none';
    color: '#fff';
    padding: "5px 12px 7px 16px";
    text-align: 'center';
    font-size: '14px';
    font-weight: '600';
    cursor: 'pointer';
    border-radius:'2';
}   

.buttonSubmit{
    padding: '7px 19px';
    margin: '0 16px';
    background-color:'#c8c8c8';
    color: '#605e5c';
    font-weight:'600';    
    border: 'none';
    cursor: 'pointer';
   }

.MuiCheckbox-colorSecondary.Mui-checked {
  color:none;
}
.MuiIconButton-colorSecondary:hover {
    background-color:none;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .MuiAccordionSummary-root {    
    background-color: rgba(0,0,0,0.08);
}

.MuiSvgIcon-root {
    font-size: 0.75rem !important;  
}
.MuiAccordionDetails-root {
    display: initial !important;
    padding: 0px 0px 0px !important; 
    display: grid !important;     
}
/* .MuiAccordionSummary-content { 
    text-decoration: underline;
} */

.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.54);
    height: 10px !important;
}

.MuiButtonGroup-root {
    display: inline-grid!important;
    border-radius: 4px;
}

.MuiButton-sizeSmall {
    text-transform: capitalize !important;
    font-size: 10px !important;
}

/* .MuiTypography-displayBlock
{
    display: block;
    font-weight: bold !important;
} */