.MuiAccordionSummary-root.Mui-expanded {
    min-height: 84px;
    background-color: #99c5ed
}

.MuiAccordionSummary-root {
    min-height: 68px;
    background-color: #cce2f6;
    
}
.Policy-Europe-Busin {
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #323130;
  }